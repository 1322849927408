/** @format */

import { Checkbox, Divider } from "@material-ui/core";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { useAuth } from "../utils/context";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import {
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import { FaFilePdf } from "react-icons/fa";
export default function Login() {
  const [accept, setAccept] = useState(false);
  const [token, setToken] = useState(null);
  const [datenschutz, setDatenschutz] = useState(null);
  const user = useAuth();
  const router = useRouter();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Keine gültige email")
      .required("Bitte Email eintragen"),
    password: Yup.string()
      .required("Bitte Passwort eingeben")
      .min(3, "Passwort muss mindestens aus 8 Zeichen bestehen")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
        "Das Passwort muss mindestens aus 8 Zeichen bestehen und einen Großbuchst, einen Kleinbuchstaben und eine Zahl enthalten."
      ),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const json = await user.login(values.email, values.password, false);

      if (json.success) {
        /* 
          Wenn Anmeldung erfolgreich, 
          aber die Datenschutzbelehrung noch nicht akzeptiert wurde,
          muss diese vor dem fortfahren zuerst akzeptiert werden. 
        */
        if (!json.datenschutz && json.data.type === "pflegeheim") {
          setDatenschutz(true);
          setSubmit(true);
          setToken(json.token);
          return;
        } else {
          router.push("/profil");
        }
      }
      user.handleAlert(json.message, json.success);
    },
  });
  /* Öffnet die Datenschutzbelehrung in einem neuen Tab */
  const handleDownload = () => {
    const pdfWindow = window.open();
    axios
      .get(`/api/datenschutz?token=${token}`, {
        responseType: "blob",
      })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window

        pdfWindow.location.href = fileURL;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /* Akzeptieren der Datenschutzbelehrung speichern */
  const handleDatenschutz = async () => {
    const json = await user.login(
      formik.values.email,
      formik.values.password,
      true
    );
    if (!json.datenschutz) {
      setDatenschutz(true);
      setSubmit(true);
      return;
    }
    user.handleAlert(json.message, json.success);
    if (json.success) {
      router.push("/profil");
    }
  };
  return (
    <div
      style={{
        borderRadius: 20,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#f2f2f2",
        margin: "5rem auto",
        padding: 20,
        maxWidth: "500px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h1>Anmeldung</h1>
      <Divider />
      {datenschutz ? (
        <>
          <FormControl name="datenschutz">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <FormControlLabel
                data-cy="button-datenschutz"
                checked={accept}
                control={<Checkbox />}
                onChange={(e) => setAccept(e.target.checked)}
              />
              <div
                style={{ fontSize: "10px", cursor: "pointer" }}
                onClick={() => setAccept(!accept)}
              >
                Ich stimme der beiliegenden Datenschutzbelehrung der
                CareNext-Plattform ausdrücklich zu. Außerdem verpflichte ich
                mich dem besonderen Schutz der personenbezogenen Patienten
                Informationen und akzeptiere die Richtlinien im Umgang mit
                diesen ausdrücklich.
              </div>
            </div>
            <a
              style={{
                display: "flex",
                alignSelf: "flex-end",
                fontSize: "13px",
              }}
              onClick={handleDownload}
            >
              <FaFilePdf />
              Datenschutzbelehrung und Richtlinien
            </a>
            <FormHelperText>
              {formik.touched.datenschutz &&
                Boolean(formik.errors.datenschutz) &&
                formik.errors.datenschutz}
            </FormHelperText>
          </FormControl>
          <Button
            data-cy="button-submit"
            disabled={!accept}
            onClick={handleDatenschutz}
          >
            Bestätigen
          </Button>
        </>
      ) : (
        <>
          <form
            onSubmit={formik.handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 10,
              justifyContent: "space-evenly",
              height: "300px",
            }}
          >
            <TextField
              fullWidth
              id="email"
              data-cy="user-email"
              name="email"
              variant="outlined"
              size="small"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              id="password"
              data-cy="user-password"
              name="password"
              variant="outlined"
              size="small"
              label="Passwort"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Button fullWidth type="submit" variant="contained">
              anmelden
            </Button>
          </form>
          <Link href="/passwort_vergessen">
            <a>Passwort vergessen?</a>
          </Link>
          {/*           <span>
            Sie haben noch kein Nutzerkonto?{" "}
            <Link href="/register">
              <a>Registrieren</a>
            </Link>
          </span> */}
        </>
      )}
    </div>
  );
}
